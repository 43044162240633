/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<i class="icon-home"></i>
```
*/
.icon-01 {
  background-image: url('/img/icons.png');
  background-position: 0px 0px;
  width: 48px;
  height: 48px;
}
.icon-02 {
  background-image: url('/img/icons.png');
  background-position: -48px 0px;
  width: 48px;
  height: 48px;
}
.icon-03 {
  background-image: url('/img/icons.png');
  background-position: 0px -48px;
  width: 48px;
  height: 48px;
}
.icon-04 {
  background-image: url('/img/icons.png');
  background-position: -48px -48px;
  width: 48px;
  height: 48px;
}
.icon-05 {
  background-image: url('/img/icons.png');
  background-position: -96px 0px;
  width: 48px;
  height: 48px;
}
.icon-06 {
  background-image: url('/img/icons.png');
  background-position: -96px -48px;
  width: 48px;
  height: 48px;
}
.icon-07 {
  background-image: url('/img/icons.png');
  background-position: 0px -96px;
  width: 48px;
  height: 48px;
}
.icon-08 {
  background-image: url('/img/icons.png');
  background-position: -48px -96px;
  width: 48px;
  height: 48px;
}
.icon-09 {
  background-image: url('/img/icons.png');
  background-position: -96px -96px;
  width: 48px;
  height: 48px;
}
.icon-10 {
  background-image: url('/img/icons.png');
  background-position: -144px 0px;
  width: 48px;
  height: 48px;
}
.icon-11 {
  background-image: url('/img/icons.png');
  background-position: -144px -48px;
  width: 48px;
  height: 48px;
}
.icon-12 {
  background-image: url('/img/icons.png');
  background-position: -144px -96px;
  width: 48px;
  height: 48px;
}
.icon-13 {
  background-image: url('/img/icons.png');
  background-position: 0px -144px;
  width: 48px;
  height: 48px;
}
.icon-14 {
  background-image: url('/img/icons.png');
  background-position: -48px -144px;
  width: 48px;
  height: 48px;
}
.icon-15 {
  background-image: url('/img/icons.png');
  background-position: -96px -144px;
  width: 48px;
  height: 48px;
}
.icon-16 {
  background-image: url('/img/icons.png');
  background-position: -144px -144px;
  width: 48px;
  height: 48px;
}
.icon-17 {
  background-image: url('/img/icons.png');
  background-position: -192px 0px;
  width: 48px;
  height: 48px;
}
.icon-18 {
  background-image: url('/img/icons.png');
  background-position: -192px -48px;
  width: 48px;
  height: 48px;
}
.icon-19 {
  background-image: url('/img/icons.png');
  background-position: -192px -96px;
  width: 48px;
  height: 48px;
}
.icon-20 {
  background-image: url('/img/icons.png');
  background-position: -192px -144px;
  width: 48px;
  height: 48px;
}
.icon-21 {
  background-image: url('/img/icons.png');
  background-position: 0px -192px;
  width: 48px;
  height: 48px;
}
.icon-22 {
  background-image: url('/img/icons.png');
  background-position: -48px -192px;
  width: 48px;
  height: 48px;
}
.icon-23 {
  background-image: url('/img/icons.png');
  background-position: -96px -192px;
  width: 48px;
  height: 48px;
}
.icon-24 {
  background-image: url('/img/icons.png');
  background-position: -144px -192px;
  width: 48px;
  height: 48px;
}
.icon-25 {
  background-image: url('/img/icons.png');
  background-position: -192px -192px;
  width: 48px;
  height: 48px;
}
.icon-26 {
  background-image: url('/img/icons.png');
  background-position: -240px 0px;
  width: 48px;
  height: 48px;
}
.icon-27 {
  background-image: url('/img/icons.png');
  background-position: -240px -48px;
  width: 48px;
  height: 48px;
}
.icon-28 {
  background-image: url('/img/icons.png');
  background-position: -240px -96px;
  width: 48px;
  height: 48px;
}
.icon-29 {
  background-image: url('/img/icons.png');
  background-position: -240px -144px;
  width: 48px;
  height: 48px;
}
.icon-30 {
  background-image: url('/img/icons.png');
  background-position: -240px -192px;
  width: 48px;
  height: 48px;
}
.icon-31 {
  background-image: url('/img/icons.png');
  background-position: 0px -240px;
  width: 48px;
  height: 48px;
}
.icon-32 {
  background-image: url('/img/icons.png');
  background-position: -48px -240px;
  width: 48px;
  height: 48px;
}
.icon-33 {
  background-image: url('/img/icons.png');
  background-position: -96px -240px;
  width: 48px;
  height: 48px;
}
.icon-34 {
  background-image: url('/img/icons.png');
  background-position: -144px -240px;
  width: 48px;
  height: 48px;
}
.icon-35 {
  background-image: url('/img/icons.png');
  background-position: -192px -240px;
  width: 48px;
  height: 48px;
}
.icon-36 {
  background-image: url('/img/icons.png');
  background-position: -240px -240px;
  width: 48px;
  height: 48px;
}
.icon-37 {
  background-image: url('/img/icons.png');
  background-position: -288px 0px;
  width: 48px;
  height: 48px;
}
.icon-38 {
  background-image: url('/img/icons.png');
  background-position: -288px -48px;
  width: 48px;
  height: 48px;
}
.icon-39 {
  background-image: url('/img/icons.png');
  background-position: -288px -96px;
  width: 48px;
  height: 48px;
}
.icon-40 {
  background-image: url('/img/icons.png');
  background-position: -288px -144px;
  width: 48px;
  height: 48px;
}
.icon-41 {
  background-image: url('/img/icons.png');
  background-position: -288px -192px;
  width: 48px;
  height: 48px;
}
