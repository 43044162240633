.App {
  text-align: center;
  font-size: 18px
}

canvas {
  width: 100%;
  height: 50vh;
}

.App-header {
  background-color: #282c34;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-login {
  color: #26B6FF;
  padding: 1rem 2rem;
  border-radius: 60px;
  font-size: 2rem;
  background: initial;
  border: 2px solid #26B6FF;
  box-shadow: 0 0 8px;
  position: fixed;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 100;
}
